import "react-app-polyfill/ie9";
import "ts-polyfill/lib/es2015-collection";
import "ts-polyfill/lib/es2015-core";
import "ts-polyfill/lib/es2016-array-include";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { telemetrySetup } from "./utils/telemetry";

import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import { setupAndGetCustomConfig } from "./utils/customConfig";
import { loadIntercom } from "./utils/intercomLoader";

const customConfig = setupAndGetCustomConfig();

if (!customConfig.intercomWidgetDisabled) {
  loadIntercom();
}

telemetrySetup();
const currentLocation = new URL(window.location.href);
const isDirect =
  (process.env.NODE_ENV === "development" &&
    currentLocation.searchParams.has("direct")) ||
  currentLocation.href.includes("direct");

if (isDirect) {
  ReactDOM.render(<div>Dummy</div>, document.getElementById("root"));
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
