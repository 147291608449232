/*
  In the future, we'll aim to incorporate this custom configuration
  into the enhanced ODA landing pages configuration, but for now,
  we're doing it based on domain name just to accommodate the DFR
  requirement in the lowest-impact way we can.
*/

interface CustomSuppressionConfig {
  ODAFooterDisabled: boolean;
  needHelpMenuDisabled: boolean;
  intercomWidgetDisabled: boolean;
  customTermsAndConditionsStyles: boolean;
}

const DEFAULT_CUSTOM_CONFIG: CustomSuppressionConfig = {
  ODAFooterDisabled: false,
  needHelpMenuDisabled: false,
  intercomWidgetDisabled: false,
  customTermsAndConditionsStyles: false
} as const;

const currentCustomConfig: CustomSuppressionConfig = {
  ...DEFAULT_CUSTOM_CONFIG
};

// Not required as of now but will allow us to keep things organised if we need to add more domains in the future
const DOMAIN_NAME_PATTERN = {
  DFR: /defencejobstesting/i,
  ADF: /adfcareers/i
  // Uncomment for local testing
  // LOCAL_HOST: /localhost/i
};

export function setupAndGetCustomConfig(): CustomSuppressionConfig {
  if (isDomainNameTriggered()) {
    currentCustomConfig.ODAFooterDisabled = true;
    currentCustomConfig.needHelpMenuDisabled = true;
    currentCustomConfig.intercomWidgetDisabled = true;
    currentCustomConfig.customTermsAndConditionsStyles = true;

    // This is a temporary solution until we have a proper white labelling solution in place. Happens after load which isn't ideal
    document.title = "Online Assessments";
  }
  return currentCustomConfig;
}

function isDomainNameTriggered(): boolean {
  const domainName = window.location.hostname;
  return Object.values(DOMAIN_NAME_PATTERN).some((pattern: RegExp) =>
    pattern.test(domainName)
  );
}

export function isCustomTermsAndConditionsStyles(): boolean {
  return currentCustomConfig.customTermsAndConditionsStyles;
}

export function isODAFooterDisabled(): boolean {
  return currentCustomConfig.ODAFooterDisabled;
}

export function isNeedHelpMenuDisabled(): boolean {
  return currentCustomConfig.needHelpMenuDisabled;
}

export function isIntercomWidgetDisabled(): boolean {
  return currentCustomConfig.intercomWidgetDisabled;
}
